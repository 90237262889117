<template>
  <div id="app1">
    <div class="header P_2" @click="goBack">
      <img src="../assets/icon30.png"  alt="">
      <span>{{title}}</span>
    </div>
    <div v-if="content == '' ">
      <img src="../assets/d552389eed21bb0ecc914eb4af122a1.jpg" alt="">
    </div>
    <div v-else>
      <div v-if="$route.query.type == 1" class="banner">
        <div>
          <div class="bannerTitle">{{title}}</div>
          <div class="bannerSubTitle">握多维度企业经营情况</div>
        </div>
        <img style="width:13rem;margin-right:0.8rem" src="../assets/bolang@2x.png" alt="">
      </div>
      <div v-if="$route.query.type == 2" class="banner" style="background-color:#FF3D09">
        <div>
          <div class="bannerTitle">{{title}}</div>
          <div class="bannerSubTitle">握多维度企业经营情况</div>
        </div>
         <img style="width:13rem;margin-right:0.8rem" src="../assets/bolang@2x.png" alt="">
      </div>
      <div v-if="$route.query.type == 3" class="banner" style="background-color:#0379FB">
        <div>
          <div class="bannerTitle">{{title}}</div>
          <div class="bannerSubTitle">握多维度企业经营情况</div>
        </div>
         <img style="width:13rem;margin-right:0.8rem" src="../assets/bolang@2x.png" alt="">
      </div>
      <div v-if="$route.query.type == 4" class="banner" style="background-color:#FF184F">
        <div>
          <div class="bannerTitle">{{title}}</div>
          <div class="bannerSubTitle">握多维度企业经营情况</div>
        </div>
         <img style="width:13rem;margin-right:0.8rem" src="../assets/bolang@2x.png" alt="">
      </div>
      <div v-if="$route.query.type == 5" class="banner" style="background-color:#4222D5">
        <div>
          <div class="bannerTitle">{{title}}</div>
          <div class="bannerSubTitle">握多维度企业经营情况</div>
        </div>
         <img style="width:13rem;margin-right:0.8rem" src="../assets/bolang@2x.png" alt="">
      </div>
      <div v-if="$route.query.type == 6" class="banner" style="background-color:#4DBA2A">
        <div>
          <div class="bannerTitle">{{title}}</div>
          <div class="bannerSubTitle">握多维度企业经营情况</div>
        </div>
         <img style="width:13rem;margin-right:0.8rem" src="../assets/bolang@2x.png" alt="">
      </div>

      <div class="P_3" v-html="content"></div>
    </div>

    <van-dialog style="text-align:center" @confirm="confirm" v-model="show">
      <div class="P_6">
          请升级套餐，查看更多内容
      </div> 
    </van-dialog>
  </div>
</template>
<script>
import {mapActions, mapState,} from "vuex"
export default {
  data(){
    return {
      content:"",
      title:"",
      show:false
    }
  },
  mounted(){
    this.getDetail()
  },
  methods:{
     ...mapActions(["getLmxDesc"]),
    getDetail(){
      console.log(this.$route.query.type)
      console.log(this.$route.query.content)
      this.getLmxDesc({
        reqType: "login" //请求类型
      }).then((res)=>{
        if(this.$route.query.type == 1) {
          let list = res.data.desc[0].child
          list.map((item)=>{
            if(item.title == this.$route.query.content) {
              this.content = item.content
              this.title = item.title
            }
          })
        }
        if(this.$route.query.type == 2) {
          let list = res.data.desc[1].child
          list.map((item)=>{
            if(item.title == this.$route.query.content) {
              this.content = item.content
              this.title = item.title
            }
          })
        }
        if(this.$route.query.type == 3) {
          let list = res.data.desc[2].child
          list.map((item)=>{
            if(item.title == this.$route.query.content) {
              this.content = item.content
              this.title = item.title
            }
          })
        }
        if(this.$route.query.type == 4) {
          console.log(22222)
          let list = res.data.desc[3].child
          console.log(list)
          list.map((item)=>{
            if(item.title == this.$route.query.content) {
              this.content = item.content
              this.title = item.title
            }
          })
        }
        if(this.$route.query.type == 5) {
          let list = res.data.desc[4].child
          list.map((item)=>{
            if(item.title == this.$route.query.content) {
              this.content = item.content
              this.title = item.title
            }
          })
        }
        if(this.$route.query.type == 6) {
          let list = res.data.desc[5].child
          list.map((item)=>{
            if(item.title == this.$route.query.content) {
              this.content = item.content
              this.title = item.title
            }
          })
        }
        if(this.content == "") {
          this.show = true
        }
      })
    },
    confirm(){
      this.$router.go(-1)
    },
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>
.banner{
  width:100%;
  background-color: #161957;
  height:8rem;
  margin:0.5rem 0;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#app1{
  background:#fff;
  padding:0.5rem;
}
.bannerTitle{
  font-size: 1.5rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  margin-left:2rem;
}
.bannerSubTitle{
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  margin-left:2rem;
  margin-top:0.4rem;
}
</style>